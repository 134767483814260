module.exports = {
  resCommon: {
    alreadySent: '인증 코드가 전송되었습니다. 나중에 다시 시도해 주세요.',
    invalidVerifyCode: '인증 코드가 잘못되었거나 만료되었습니다.',
    walletTaken: '지갑 주소가 등록되었습니다',
    differentWallet: '사용자 이름이 등록되었습니다',
    alreadyRegistered: '사용자 이름과 지갑이 등록되었습니다',
    addressTaken: '우편 주소가 이미 등록되었습니다.',
    invalidInviteCode: '초대 코드가 만료되었습니다',
    invalidCredential: '잘못된 사용자 이름 또는 암호',
    notRegistered: '사용자 이름이 등록되지 않았습니다',
    notAuthenticated: "로그인이 만료되었습니다. 다시 로그인하십시오.",
    phoneTaken: '전화번호는 이미 사용 중입니다.',
    sendFailed: '전송 실패',
    invalidEmail: '잘못된 이메일',
    haveParentCode: '이전에 초대 코드를 바인딩했습니다.',
    invalidImgCode: '이미지 인증 코드 오류',
    eth_noLogin: '로그인하지 않았습니다. 페이지를 새로고침하세요.',
    eth_noAccount: '정보가 잘못되었습니다',
    eth_invalidAccount: '잘못된 지갑 주소',
    eth_noNonce: '정보가 잘못되었습니다. 새로고침하고 다시 시도하세요.',
    eth_noVote: '정보가 잘못되었습니다',
    eth_haveVote: '당신은 이미 투표하셨습니다',
    eth_noDPR: '지갑에 DPR이 없습니다.'
  },
  claim: {
    'WelcometoDeeperNetworkClaim': 'Welcome to Deeper Network Claim',
    "MyWallet": "My Wallet",
    Address: 'Address',
    'DPRBalance': 'DPR Balance',
    'ClaimInformationDPR': "Claim Information - DPR",
    "Total": "Total",
    "Unlocked": "Unlocked",
    "Released": "Released",
    "Available": "Available",
    "TransfertoMyWallet": "Transfer to My Wallet",
    "FirstTimeAuthentication": "First Time Authentication",
    info1: '1. You need to click "First Time Authentication" button before you claim DPR at the first time.',
    info2: '2. You can transfer available DPR to your wallet by "Transfer to My Wallet" button',
    addressNotInList: "The wallet cannot participate in the event."
  },
  navbar: {
    'User Information': '사용자 정보',
    'Reset Password': '암호를 재설정',
    'Sign Out': '로그아웃',
    'Sign In': '로그인',
    'Sign Up': '가입하기'
  },
  sale: {
    'UserInfo': '사용자 정보',
    mobile: '이동하는',
    DPRWallet: 'ERC20 Wallet',
    "Delivery Information": '배송 정보',
    AddressInfo: '배송 정보',
    Username: '사용자 이름',
    Name: '이름',
    Phone: '핸드폰',
    "Country": '국가',
    'Province': '시/도',
    'City': '도시',
    'Address': '주소',
    'Zip': '우편번호',
    'walletAddress': '지갑 주소',
    'Deeper Chain Wallet': 'Deeper Chain Wallet',
    walletTip: 'Polkawallet 또는 Polka를 지원하는 지갑을 사용하여 니모닉 단어 또는 개인 키를 가져올 수 있습니다.',
    Edit: '편집하다',
    inputName: '이름을 입력하세요',
    inputPhone: '전화번호를 입력해주세요',
    inputPhoneError: '유효하지 않은 전화 번호',
    inputCountry: '국가를 입력하세요',
    inputProvince: '지역을 입력하세요',
    inputCity: '도시를 입력하세요',
    inputZip: '우편번호를 입력하세요',
    inputAddress: '주소를 입력해주세요',
    Submit: '제출하다',
    'Create Wallet': 'Create wallet',
    Stake: '말뚝',
    MyIntention: '스테이킹 의도',
    MyInviteCode: '내 추천 코드',
    Skip: '지금은 아니다',
    ChangeWallet: '지갑 수정',
    email: '이메일',
    InviteTip: '초대된 사용자가 다음 라운드의 스테이킹 경쟁에서 승리하면 300DPR의 보상을 받을 수 있습니다.',
    BindInviteCode: '내 초대자 바인딩',
    InviteRecord: '초대 기록',
    '2fa': '2FA',
    tracking: '택배 추적',
    express: '택배회사',
    trackingNumber: '운송 번호',
    returnDPR: '당신은 제네시스 마이닝 머신을 사용할 자격이 없습니다. 코인을 환불하려면 여기를 클릭하세요.',
    getPromotionReward: '프로모션 보상 받기',
  },
  register: {
    'VerificationCode': '확인 코드',
    inputCode: '인증코드를 입력해주세요',
    Send: '보내다',
    Password: '비밀번호',
    PasswordAgain: '비밀번호 확인',
    inputPassword: '비밀번호는 영숫자 이상 8자 이상이어야 합니다.',
    inputPasswordAgain: '비밀번호를 확인해주세요',
    'DPR Wallet': 'ERC20 wallet',
    inputDPRWallet: 'Please input your ERC20 wallet',
    toSignIn: '로그인',
    forgetPsw: '비밀번호 분실?',
    'UserInfo': '사용자 정보',
    mobile: '이동하는',
    email: '이메일',
    inputEmail: '이메일을 입력하세요',
    DPRWallet: 'ERC20 wallet',
    "Delivery Information": '배송 정보',
    "Country": '국가',
    'Province': '주/도',
    'City': '도시',
    'Address': '주소',
    'Zip': '우편번호',
    InviteCode: '추천 코드',
    'walletAddress': '주소',
    'Deeper Chain Wallet': 'Deeper Chain Wallet',
    Edit: '편집하다',
    inputCountry: '국가를 입력하세요',
    inputProvince: '지역을 입력하세요',
    inputCity: '도시를 입력하세요',
    inputZip: '우편번호를 입력하세요',
    inputInviteCode: '있다면 추천인 코드를 입력하세요.',
    inputAddress: '주소를 입력해주세요',
    Submit: '제출하다',
    registerSuccess: '성공적으로 등록하십시오!',
    imgCode: '이미지 인증 코드',
    inputImgCode: '이미지 인증 코드를 입력하세요'
  },
  login: {
    'VerificationCode': '확인 코드',
    inputCode: '인증코드를 입력해주세요',
    Send: '보내다',
    mobile: '이동하는',
    email: '이메일',
    inputEmail: '이메일을 입력하세요',
    Password: '비밀번호',
    inputPassword: '비밀번호를 입력해주세요',
    'Sign In': '로그인',
    'Sign Up': '가입하기',
    forgetPsw: '비밀번호 분실?',
    imgCode: '이미지 인증 코드',
    inputImgCode: '이미지 인증 코드를 입력하세요'
  },
  changePassword: {
    mobile: 'Mobile',
    email: 'Email',
    inputEmail: '이메일을 입력하세요',
    VerificationCode: '암호',
    inputCode: '코드를 입력하세요',
    Send: '보내다',
    Password: '비밀번호',
    PasswordAgain: '비밀번호 확인',
    inputPassword: '새로운 비밀번호를 입력해주세요',
    inputPasswordAgain: '새로운 비밀번호를 확인해주세요',
    Submit: '제출하다',
    imgCode: '이미지 인증 코드',
    inputImgCode: '이미지 인증 코드를 입력하세요'
  },
  vuePhoneComponent: {
    Callingcode: 'Calling code',
    EnterPhoneNumber: 'Enter phone number'
  },
  createWallet: {
    clickInOrder: '지금 바로 아래의 버튼들을 니모닉 순서대로 클릭해주세요.',
    recordTips: '메모 아래에 다음 니모닉 단어를 반드시 사용하고, 올바르게 보관하고, 스크린샷을 찍거나 복사하여 인터넷에 저장하지 마십시오. 니모닉 문구를 분실하면 지갑의 자산이 영구적으로 손실되며, 지갑을 회수할 수 있습니다(Deeper 팀 포함).',
    Reset: '초기화',
    Confirm: '확인하다',
    confirmBtnText: '니모닉 문구를 적어두었어요',
    confirmError: '잘못된 주문',
    DownloadKey: '개인 키 다운로드',
    Back: '뒤쪽에',
    createTips: "스테이킹 활동에는 Deeper Chain 지갑이 필요합니다.",
    createNow: '지갑 생성',
    notNow: '지금은 아니다'
  },
  changeWallet: {
    DeeperWallet: 'DeeperChain Wallet Address',
    inputDeeperWallet: 'Please input new DeeperChain wallet address',
    Submit: 'Submit',
    CreateWallet: 'Create a new wallet'
  },
  stakeMiner: {
    RegisteredInformation: '등록 정보',
    DPRWallet: 'ERC20 Wallet',
    'Deeper Chain Wallet': 'Deeper Chain Wallet',
    StakingInformation: '스테이킹 정보',
    DPRStaked: 'DPR 스테이킹',
    StakingLevel: '스테이킹 레벨',
    CreditScore: '신용 점수',
    NumberOfReferralCodes: '추천 코드 수',
    ReleaseTime: '출시 시간',
    DeeperChainWalletStaked: 'Deeper Chain Wallet',
    'Pleaseuseyourregisteredwallettooperate': '등록된 지갑을 이용하여 운영하시기 바랍니다. 지갑에 성공적으로 연결되면 지갑에 DPR 잔액이 표시됩니다.',
    WalletInformation: '지갑 정보',
    DPRBalance: 'DPR 균형',
    'Pleaseselectstakinglevel': '스테이킹하려는 신용 점수 등급을 선택하십시오.',
    Need: '필요',
    Needmore: '더 필요',
    Get: '얻다',
    getCreditScore: '신용 점수',
    getNumberOfReferralCodes: '추천 코드 수',
    Stake: 'Stake',
    clicktocopy: '초대 링크 복사 클릭',
    invitedUser: '초대된 사용자',
    moveToLatestPeriod: '최신 기간으로 이동',
    toLeaderboard: '리더보드',
    startSoon: '출시 예정',
    checkNetwork: '지갑 네트워크가 ETH 메인 네트워크인지 확인하십시오',
    ETHBalance: 'ETH 잔액',
    ETHBalanceTip: "이더리움 가스 수수료를 위해 ERC20 지갑에 최소 0.1 ETH를 보유하는 것이 좋습니다.",
    tutorial: '지도 시간',
    buyDPR: 'Uniswap에서 DPR 구매'
  },
  intention: {
    Pleaseselectstakinglevel: '스테이킹하려는 신용 점수 등급을 선택하십시오.',
    Need: '필요',
    Get: '얻다',
    getCreditScore: '신용 점수',
    getNumberOfReferralCodes: '추천 코드 수',
    Stake: 'Stake',
    Save: '구하다',
    baseIncome: 'Total Income (9 mth stake period)',
    baseIncomeDaily: 'Total Daily Income',
    addIncome: 'Total Income w/ Phase #1 Bonus (9mth stake period)',
    addIncomeDaily: 'Total Daily Income w/ Phase #1 Bonus',
    tips: 'You can change it later when you start staking. This is just an intention. ',
    Predict: 'Predict',
    DPRPrice: 'DPR price'
    // tips: "Please select the credit score tier you want to stake, and this is just an intention. You can change it later when you start staking. "
  },
  inviteCode: {
    InviteCode: '추천 코드:',
    InviteQRCode: '추천 QR코드:',
    clicktodownload: '다운로드하려면 클릭',
    InviteUrl: '추천 URL:',
    clicktocopy: '복사하려면 클릭',
    inviteRecords: '추천 기록',
    invitedUser: '추천 사용자',
    registerTime: '등록 시간',
    stakingAmount: '스테이킹 금액',
    stakingTime: '스테이킹 시간',
    inviteTutorial: '독점 튜토리얼을 열려면 클릭하십시오'
  },
  common: {
    inform1: '서약 콘테스트가 곧 시작됩니다. 인내심을 갖고 언제든지 공지 사항을 따르십시오. 서약 콘테스트가 시작되면 ERC20 지갑 주소와 Deeper Chain 지갑은 수정이 불가하오니 빠른 시일 내에 관련 정보를 확인해주시기 바랍니다.',
    '两次密码输入不一致': '두 개의 비밀번호 항목이 일치하지 않습니다.',
    '请输入正确的DPR钱包地址': '올바른 ETH 지갑 주소를 입력하세요.',
    '请输入手机号码': '전화번호를 입력해주세요',
    '请输入正确的邮箱地址': '정확한 이메일을 입력하세요',
    '请输入验证码': '전화 인증 코드를 입력하세요',
    '请输入密码': '비밀번호를 입력해주세요',
    '请输入正确的手机号码': "정확한 전화번호를 입력하세요",
    '请先登录': '먼저 로그인하시기 바랍니다',
    '登录超时，请重新登录': '로그인 시간이 초과되었습니다. 다시 로그인하십시오.',
    "请完善钱包地址": 'ERC20 주소와 Deeper Chain 주소를 입력하세요.',
    "请尽快完善收货地址信息": '가능한 한 빨리 배송 주소 정보를 작성하십시오',
    '请输入图片验证码': '이미지 인증 코드를 입력하세요'
  },
  leaderboard: {
    yourPosition: '당신의 위치',
    tips: '팁: 데이터가 지연됩니다. 업데이트되지 않은 경우 나중에 다시 확인하십시오.'
  },
  calculator: {
    Need: 'Staked DPR Tokens',
    Get: 'DPR Mining Rewards',
    title: 'Credit Score Tier',
    DPRPrice: '$DPR Price',
    getCreditScore: 'Credit Score',
    ReferralTickets: 'Referral Tickets',
    Daily: 'Daily Rewards',
    Monthly: 'Monthly Rewards',
    Yearly: 'Yearly Rewards',
    calculatorTitle: 'Mini, Nano, Pico Calculator'
  }
}