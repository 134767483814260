
import jwt from 'jsonwebtoken';
import PhoneInput from '@/components/PhoneInput/PhoneInput'
const validateEmail = require('@/tools/validateEmail') 
import Footer from '@/components/Footer/Footer'
export default {
  components: {
    PhoneInput,
    Footer
  },
  data() {
    return {
      needTowFA: false,
      submitLoading: false,
      tabIndex: 1,
      firstLogin: false,
      codeCount: 0,
      codeLoading: false,
      imgCodeUrl: '',
      form: {
        phoneObj: {},
        phone: '',
        email: '',
        phoneCode: '',
        password: '',
        personCode: '',
        verifyCode: ''
      }
    }
  },
  mounted() {
    const queryTab = this.$route.query.tab;
    if (queryTab !== undefined) {
      this.tabIndex = queryTab/1
    }
    const firstLogin = this.$route.query.first;
    this.firstLogin = firstLogin
  },
  methods: {
    get2FA() {
      if (this.needTowFA) {
        this.onSubmit()
        return
      }
      this.submitLoading = true
      this.$fetch({
        url: '/user/2fa',
        params: {
          user: this.tabIndex == 0 ? this.form.phoneObj.formattedNumber : this.form.email,
        }
      }).then(res => {
        if (res.data && res.data.status == 'OPEN') {
          this.imgCodeUrl = this.$BASEURL+'captcha?'+Date.now();
          this.needTowFA = true
        } else {
          this.submitLoading = false
          this.onSubmit()
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    getCode() {
      if (!this.form.imgCode) {
        return this.$ctToast("请输入图片验证码");
      }
      if (this.tabIndex == 0) {
        if (!this.form.phoneObj.formattedNumber) {
          return this.$ctToast("请输入正确的手机号码");
        }
      }
      if (this.tabIndex == 1) {
        if (!validateEmail(this.form.email)) {
          return this.$ctToast("请输入正确的邮箱地址");
        }
      }
      
      this.codeLoading = true;
      this.$fetch({
        headers: {
          "mock-res-index": 1,
        },
        url: this.tabIndex == 1 ? "/user/generateEmailCode" : "/user/generateCode",
        method: "POST",
        data: {
          imgCode: this.form.imgCode,
          phone: this.form.phoneObj.formattedNumber,
          email: this.form.email
        },
      })
        .then((res) => {
          this.startCodeCount();
        })
        .finally(() => {
          this.codeLoading = false;
        });
    },
    async startCodeCount() {
      this.codeCount = 60;
      while (this.codeCount > 0) {
        await this.$sleep(1000);
        this.codeCount--;
      }
    },
    t(params) {
      return this.$t(`login.${params}`)
    },
    onSubmit(e) {
      if (this.tabIndex == 0 && !this.form.phoneObj.formattedNumber) {
        return this.$ctToast('请输入正确的手机号码')
      }
      if (this.tabIndex == 1 && !validateEmail(this.form.email)) {
        return this.$ctToast('请输入正确的邮箱地址')
      }
      if (!this.form.password) {
        return this.$ctToast('请输入密码')
      }
      this.submitLoading = true
      this.$fetch({
        headers: {
          'mock-res-index': 1
        },
        url: '/user/signIn',
        method: 'POST',
        data: {
          username: this.tabIndex == 0 ? this.form.phoneObj.formattedNumber : this.form.email,
          password: encryptWithPublicKey(this.form.password),
          verifyCode: this.form.verifyCode
        }
      }).then(res => {
        console.log(res)
        this.$toast.success()
        const token = res.token.replace('Bearer ', '')
        const tokenData = jwt.decode(token)
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('tokenData', JSON.stringify(tokenData))
        if (this.firstLogin) {
          this.$router.push("/user?first=true")
        } else {
          this.$router.push("/user")
        }
        
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
