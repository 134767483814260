module.exports = {
  resCommon: {
    alreadySent: 'The verification code has been sent, please try again later.',
    invalidVerifyCode: 'The verification code is incorrect or has expired',
    walletTaken: 'The wallet address has been registered',
    differentWallet: 'The username has been registed',
    alreadyRegistered: 'The username and wallet have been registered',
    addressTaken: 'The mailing address has already been registered',
    invalidInviteCode: 'The invitation code has expired',
    invalidCredential: 'wrong user name or password',
    notRegistered: 'The username is not registered',
    notAuthenticated: "Login expired, please login again",
    phoneTaken: 'The phone number is already in use',
    sendFailed: 'Failed to send',
    invalidEmail: 'invalid Email',
    haveParentCode: 'You have bound the invitation code before',
    invalidImgCode: 'Invalid image verification code',
    eth_noLogin: 'You are not logged in, please refresh the page',
    eth_noAccount: 'The information is wrong',
    eth_invalidAccount: 'This wallet address cannot participate in this event',
    eth_noNonce: 'The information is wrong, please refresh and try again',
    eth_noVote: 'The information is wrong',
    eth_haveVote: 'You have already voted',
    eth_noDPR: 'Your wallet does not have DPR'
  },
  claim: {
    'WelcometoDeeperNetworkClaim': 'Welcome to Deeper Network Claim',
    "MyWallet": "My Wallet",
    Address: 'Address',
    'DPRBalance': 'DPR Balance',
    'ClaimInformationDPR': "Claim Information - DPR",
    "Total": "Total",
    "Unlocked": "Unlocked",
    "Released": "Released",
    "Available": "Available",
    "TransfertoMyWallet": "Transfer to My Wallet",
    "FirstTimeAuthentication": "First Time Authentication",
    info1: '1. You need to click "First Time Authentication" button before you claim DPR at the first time.',
    info2: '2. You can transfer available DPR to your wallet by "Transfer to My Wallet" button',
    info3: '3. If the required gas is too expensive, please operate at a cheaper price.',
    addressNotInList: "The wallet cannot participate in the event.",
    releasedTime: 'Unlock Time',
    lockBtn: 'Get 5% extra reward',
    claimBtn: 'Give up rewards',
    youHaveClaimed: 'You have withdrawn',
    DPRtoClaim: 'The total amount of DPR available',
    firstReward: 'The first round of rewards',
    secondReward: 'The second round of rewards',
    backToClaim: 'View previous contract'
  },
  navbar: {
    'User Information': 'User Information',
    'Reset Password': 'Reset Password',
    'Sign Out': 'Sign Out',
    'Sign In': 'Sign In',
    'Sign Up': 'Sign Up'
  },
  sale: {
    'UserInfo': 'User Information',
    mobile: 'Mobile',
    DPRWallet: 'ERC20 Wallet',
    "Delivery Information": 'Delivery Information',
    AddressInfo: 'Delivery Information',
    Username: 'username',
    Name: 'Name',
    Phone: 'Phone',
    "Country": 'Country',
    'Province': 'State/Province',
    'City': 'City',
    'Address': 'Address',
    'Zip': 'Zip/Postal Code',
    'walletAddress': 'Address',
    'Deeper Chain Wallet': 'Deeper Chain Wallet',
    walletTip: 'You can use a Polkadot wallet or Polkadot supported wallet to import your mnemonic phrase or private key',
    Edit: 'Edit',
    inputName: 'Please input your name',
    inputPhone: 'Please input your phone number',
    inputPhoneError: 'Invalid phone number',
    inputCountry: 'Please input country',
    inputProvince: 'Please input province',
    inputCity: 'Please input city',
    inputZip: 'Please input zip',
    inputAddress: 'Please input address',
    Submit: 'Submit',
    'Create Wallet': 'Create wallet',
    Stake: 'Stake',
    MyIntention: 'Staking Intention',
    MyInviteCode: 'My Referral Code',
    Skip: 'Not now',
    ChangeWallet: 'Change Wallet',
    email: 'Email',
    InviteTip: 'If a referee is selected as a genesis node, you will earn 300 $DPR per successful referral.',
    BindInviteCode: 'Bind a referral code',
    InviteRecord: 'Invitation record',
    '2fa': '2FA',
    tracking: 'Tracking',
    express: 'Logistics company',
    trackingNumber: 'Tracking number',
    returnDPR: 'You are not qualified for the Genesis Mining Machine, please click here to refund the coin.',
    getPromotionReward: 'Receive promotion rewards',
    
  },
  register: {
    'VerificationCode': 'Verification Code',
    inputCode: 'Please input Verification code',
    Send: 'Send',
    Password: 'Password',
    PasswordAgain: 'Confirm Password',
    inputPassword: 'Password must be alphanumeric and at least 8 characters long.',
    inputPasswordAgain: 'Please confirm password',
    'DPR Wallet': 'ERC20 wallet',
    inputDPRWallet: 'Please input your ERC20 wallet',
    toSignIn: 'Sign in',
    forgetPsw: 'Forget password?',
    'UserInfo': 'User Information',
    mobile: 'Mobile',
    email: 'Email',
    inputEmail: 'Please input Email',
    DPRWallet: 'ERC20 wallet',
    "Delivery Information": 'Delivery Information',
    "Country": 'Country',
    'Province': 'State / Province',
    'City': 'City',
    'Address': 'Address',
    'Zip': 'Zip / Postal Code',
    InviteCode: 'Referral code',
    'walletAddress': 'Address',
    'Deeper Chain Wallet': 'Deeper Chain Wallet',
    Edit: 'Edit',
    inputCountry: 'Please input country',
    inputProvince: 'Please input province',
    inputCity: 'Please input city',
    inputZip: 'Please input zip',
    inputInviteCode: 'Please input referral code if you have',
    inputAddress: 'Please input address',
    Submit: 'Submit',
    registerSuccess: 'Register successfully!',
    imgCode: 'Image code',
    inputImgCode: 'Please enter the image code'
  },
  login: {
    'VerificationCode': 'Verification Code',
    inputCode: 'Please input Verification code',
    Send: 'Send',
    mobile: 'Mobile',
    email: 'Email',
    inputEmail: 'Please input Email',
    Password: 'Password',
    inputPassword: 'Please input password',
    'Sign In': 'Sign in',
    'Sign Up': 'Sign up',
    forgetPsw: 'forget password?',
    imgCode: 'Image code',
    inputImgCode: 'Please enter the image code'
  },
  changePassword: {
    mobile: 'Mobile',
    email: 'Email',
    inputEmail: 'Please input Email',
    VerificationCode: 'Code',
    inputCode: 'Please input code',
    Send: 'Send',
    Password: 'Password',
    PasswordAgain: 'Confirm Password',
    inputPassword: 'Please input new password',
    inputPasswordAgain: 'Please confirm new password',
    Submit: 'Submit',
    imgCode: 'Image code',
    inputImgCode: 'Please enter the image code'
  },
  vuePhoneComponent: {
    Callingcode: 'Calling code',
    EnterPhoneNumber: 'Enter phone number'
  },
  createWallet: {
    clickInOrder: 'Please click the buttons below in order according to the mnemonic just now.',
    recordTips: 'Please be sure to use the following mnemonic words under your notes, and keep them properly, do not take screenshots or copy and save them on the Internet.If the mnemonic phrase is lost, the assets in the wallet will be permanently lost, and no one can retrieve the wallet (including the Deeper team).',
    Reset: 'Reset',
    Confirm: 'Confirm',
    confirmBtnText: 'I wrote down the mnemonic phrase',
    confirmError: 'Incorrect order',
    DownloadKey: 'Download private key',
    Back: 'Back',
    createTips: "Staking activities require Deeper Chain wallet",
    createNow: 'Create wallet',
    notNow: 'Not now'
  },
  changeWallet: {
    DeeperWallet: 'DeeperChain Wallet Address',
    inputDeeperWallet: 'Please input new DeeperChain wallet address',
    Submit: 'Submit',
    CreateWallet: 'Create a new wallet'
  },
  stakeMiner: {
    RegisteredInformation: 'Registered Information',
    DPRWallet: 'ERC20 Wallet',
    'Deeper Chain Wallet': 'Deeper Chain Wallet',
    StakingInformation: 'Staking Information',
    DPRStaked: 'DPR Staked',
    StakingLevel: 'Staking Level',
    CreditScore: 'Credit Score',
    NumberOfReferralCodes: 'Referral Tickets',
    ReleaseTime: 'Release Time',
    DeeperChainWalletStaked: 'Deeper Chain Wallet',
    'Pleaseuseyourregisteredwallettooperate': 'Please use your registered wallet to operate. If you successfully connect to the wallet, you will see the DPR balance in the wallet.',
    WalletInformation: 'Wallet Information',
    DPRBalance: 'DPR Balance',
    'Pleaseselectstakinglevel': 'Please select the credit score tier you want to stake.',
    Need: 'Need',
    Needmore: 'Need more',
    Get: 'Get',
    getCreditScore: 'Credit Score',
    getNumberOfReferralCodes: 'Referral Tickets',
    Stake: 'Stake',
    clicktocopy: 'Click copy invitation link',
    invitedUser: 'Invited user',
    moveToLatestPeriod: 'Join phase 2',
    toLeaderboard: 'Leaderboard',
    startSoon: 'Coming soon',
    checkNetwork: 'Please confirm that your wallet network is the ETH main network',
    ETHBalance: 'ETH balance',
    ETHBalanceTip: "We recommend you have at least 0.1 ETH in your ERC20 wallet for Ethereum gas fees.",
    tutorial: 'Tutorial',
    buyDPR: 'Buy DPR on Uniswap'
  },
  intention: {
    Pleaseselectstakinglevel: 'Please select the credit score tier you want to stake.',
    Need: 'Need',
    Get: 'Get',
    getCreditScore: 'Credit Score',
    getNumberOfReferralCodes: 'Number of Referral Codes',
    Stake: 'Stake',
    Save: 'Save',
    baseIncome: 'Total Income (9 mth stake period)',
    baseIncomeDaily: 'Total Daily Income',
    addIncome: 'Total Income w/ Phase #1 Bonus (9mth stake period)',
    addIncomeDaily: 'Total Daily Income w/ Phase #1 Bonus',
    tips: 'You can change it later when you start staking. This is just an intention. ',
    Predict: 'Predict',
    DPRPrice: 'DPR price'
    // tips: "Please select the credit score tier you want to stake, and this is just an intention. You can change it later when you start staking. "
  },
  inviteCode: {
    InviteCode: 'Referral code:',
    InviteQRCode: 'Referral QRCode:',
    clicktodownload: 'click to download',
    InviteUrl: 'Referral URL:',
    clicktocopy: 'click to copy',
    inviteRecords: 'Referral records',
    invitedUser: 'Referral user',
    registerTime: 'register time',
    stakingAmount: 'staking amount',
    stakingTime: 'staking time',
    inviteTutorial: 'Click to open exclusive tutorial'
  },
  common: {
    inform1: 'The staking is about to start, please be patient and follow our notice at any time. When the staking starts, the ERC20 wallet address and Deeper Chain wallet cannot be modified. Please confirm the relevant information as soon as possible.',
    '两次密码输入不一致': 'Two password entries are inconsistent',
    '请输入正确的DPR钱包地址': 'Please enter the correct ERC20 wallet address',
    '请输入手机号码': 'Please enter the phone number',
    '请输入正确的邮箱地址': 'Please enter the correct Email',
    '请输入验证码': 'Please enter the phone verification code',
    '请输入密码': 'Please enter the password',
    '请输入正确的手机号码': "Please enter the correct phone number",
    '请先登录': 'please log in first',
    '登录超时，请重新登录': 'Login timed out, please log in again',
    "请完善钱包地址": 'Please complete the ERC20 address and Deeper Chain address',
    "请尽快完善收货地址信息": 'Please complete the delivery address information as soon as possible',
    '请输入图片验证码': 'Please enter the image verification code'
  },
  leaderboard: {
    yourPosition: 'Your position',
    tips: 'Tip: The data is delayed. If it has not been updated, please check again later.'
  },
  calculator: {
    Need: 'Staked DPR Tokens',
    Get: 'DPR Mining Rewards',
    title: 'Credit Score Tier',
    DPRPrice: '$DPR Price',
    getCreditScore: 'Credit Score',
    ReferralTickets: 'Referral Tickets',
    Daily: 'Daily Rewards',
    Monthly: 'Monthly Rewards',
    Yearly: 'Yearly Rewards',
    calculatorTitle: 'Mini, Nano, Pico Calculator',
    phase1: 'Phase-1',
    phase2: 'Phase-2',
    base: 'Basic'
  }
}