
import Vue from 'vue';
export default {
  props: {
    navTitle: {
      type: String,
      default: "DEEPER NETWORK"
    }
  },
  data() {
    return {
      show: false,
      haveLogin: false,
    }
  },
  computed: {
    lang() {
      return Vue.prototype.$$i18n.locale
    }
  },
  mounted() {
    this.checkLogin()
    
  },
  methods: {
    t(word) {
      return this.$t(`navbar.${word}`)
    },
    checkLogin() {
      const token = window.localStorage.getItem('token');
      if (!token) {
        this.haveLogin = false
        return 
      }
      this.haveLogin = true
    },
    logout() {
      window.localStorage.clear()
      window.location.href= '/login'
    }
  }
}
