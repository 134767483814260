module.exports = {
  address: "0xDdb1259D3707eBF95F036A2Ab44Cb32A4e09795a",
  42: {
    address: '0x45464c4AdE5aD9c98F8a63387E33423c1d0Be11D'
  },
  1: {
    address: "0xDdb1259D3707eBF95F036A2Ab44Cb32A4e09795a",
  },
  abi: require('./ReleasedLock.abi.json')
}
