
import create from './create'
function shuffle(arr) {
  var i = arr.length, t, j;
  while (i) {
    j = Math.floor(Math.random() * i--);
    t = arr[i];
    arr[i] = arr[j];
    arr[j] = t;
  }
  return arr
}

export default {
  data() {
    return {
      show: false,
      show1: false,
      mnemonicList: [],
      shuffleList: [],
      mnemonic: '',
      privateKey: '',
      publicKey: '',
      checkStr: '',
      type: ''
    };
  },
  methods: {
    download() {
      const filename = 'deeperchain_key.txt';
      const text = this.privateKey;
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
    
      element.style.display = 'none';
      document.body.appendChild(element);
    
      element.click();
    
      document.body.removeChild(element);
    },
    t(param) {
      return this.$t(`createWallet.${param}`)
    },
    resetCheckStr() {
      this.shuffleList = this.shuffleList.map(it => {
        it.show = true;
        return it
      })
      this.checkStr = ''
    },
    addCheckStr(item, index) {
      this.checkStr += ' ' + item.word;
      this.shuffleList[index].show = false
    },
    create(type) {
      this.checkStr = '';
      this.mnemonicList = [];
      this.shuffleList = [];
      this.mnemonic = '';
      this.privateKey = '';
      this.publicKey = ''
      this.createWallet()
      this.type = type || ''
    },
    submitWallet() {
      if (this.checkStr.trim() !== this.mnemonic.trim()) {
        return this.$notify({
          type: 'danger',
          message: this.t('confirmError')
        })
      }
      this.$Toast.loading({
        forbidClick: true,
        duration: 0
      });
      this.$fetch({
         headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        // method: this.type == 'EDIT' ? 'PUT' :"POST",
        method: 'POST',
        url: "/user/deeperwallet",
        data: {
          deeperWallet: this.publicKey
        }
      }).then(res => {
        this.$notify({
          type: 'success',
          message: 'Success'
        })
        this.show = false;
        this.$emit('success')
      }).finally(() => {
        this.$Toast.clear()
      })
    },
    createWallet() {
      let {mnemonic, publicKey, privateKey} = create();
      this.$Dialog.confirm({
        message: this.t('createTips'),
        confirmButtonText: this.t('createNow'),
        cancelButtonText: this.t('notNow')
      }).then(() => {
        this.$Dialog({
          message: this.t('recordTips')
        }).then(() => {
          this.show1 = true
          this.mnemonic = mnemonic
          this.mnemonicList = mnemonic.split(' ')
          this.shuffleList = shuffle(mnemonic.split(' ').map(it => {
            return {
              word: it,
              show: true
            }
          }))
          this.privateKey = privateKey
          this.publicKey = publicKey
        });
      }).catch(() => {
        this.$emit('success')
      })
    },
  },
};
