
export default {
  data() {
    return {
      show: false,
      prize: {

      },
      lang: {
        en: {
          title: 'Congratulations',
          ok: 'OK'
        },
        cn: {
          title: '祝贺你！',
          ok: '好的'
        },
        ko: {
          title: '축하합니다',
          ok: '좋아요'
        }
      }
    }
  },
  computed: {
    word() {
      let lang = this.$$i18n.locale;
      let langMap = {
        en: `<div>
          Congratulations on ranking <b style="font-size: 20px">${this.prize.index}</b> in the <b style="font-size: 20px"> phase-${this.prize.round} </b> of Deeper Network's genesis node mining event!
        </div> 
        <div>
          Please make sure to verify and complete your delivery address in order to receive the device in time for mainnet launch. Thank you for participating and congratulations again!
        </div>
        `,
        cn: `恭喜您获得Deeper Network 创世节点设备空投活动 <b style="font-size: 20px">第${this.prize.round}轮</b> 质押 <b style="font-size: 20px"> 第${this.prize.index}名 </b> 。请及时完善您的收货地址以便Deeper 公司发货。感谢您的参与！`,
        ko: `<div>
          <b style="font-size: 20px"> phase-${this.prize.round} </b>에서 <b style="font-size: 20px">${this.prize.index}</b> 순위를 정하신 것을 축하합니다. Deeper Network의 제네시스 노드 마이닝 이벤트!
        </div>
        <div>
          메인넷 런칭 시간에 맞춰 기기를 수령할 수 있도록 배송 주소를 반드시 확인하고 작성해 주세요. 참여해주셔서 감사하고 다시한번 축하드립니다!
        </div>`
      }
      return langMap[lang]
    }
  },
  methods: {
    async check(noShow) {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/prize'
      }).then(res => {
        console.log(res)
        if (res.data) {
          this.prize = {
            round: res.data.round,
            index: res.data.index
          }
          if (!noShow) {
            this.show = true
          }
          return res.data
        }
      })
    },
  }
}
