
import mmlogo from './imgs/mm-logo.svg'
import wclogo from './imgs/walletconnect-logo.svg'
import ethService from '@/services/eth.js'

export default {
  props: {
    account: String
  },
  data() {
    return {
      show: false,
      logoImg: {
        mm: mmlogo,
        wc: wclogo
      },
      lang: {
        en: {
          connect: 'Connect Wallet'
        },
        cn: {
          connect: '连接钱包'
        },
        ko: {
          connect: '지갑 연결'
        }
      }
    }
  },
  methods: {
    async changeWalletConnect() {
      await ethService.walletConnect().catch(e => {
        console.log(e)
      })
      this.$emit('change')
      this.show = false
    },
    async changeMetamask() {
      await ethService.metamask()
      this.$emit('change')
      this.show = false
    },
    openModal() {
      this.show = true
    }
  }
}
