import Vue from "vue";
import Router from "vue-router";
import jwt from "jsonwebtoken";

import Register from "@/containers/Register/Register";
import RegisterSuccess from "@/containers/Register/RegisterSuccess";
import Login from "@/containers/Login/Login";
import KolLogin from "@/containers/Login/KolLogin";
import ChangePassword from "@/containers/ChangePassword/ChangePassword";
import User from "@/containers/User/User";
import InviteRecordKOL from "@/containers/User/InviteRecordKOL";
import StakeMiner from "@/containers/StakeMiner/StakeMiner";

function beforeEnter(to, from, next) {
  const token = window.localStorage.getItem("token");
  if (!token) {
    Vue.prototype.$Notify({
      type: "danger",
      message: Vue.prototype.$ct("请先登录"),
    });
    next("/login");
    return;
  }
  const tokenData = jwt.decode(token);
  console.log(tokenData);
  if (tokenData.exp < Date.now() / 1000) {
    Vue.prototype.$Notify({
      type: "danger",
      message: Vue.prototype.$ct("登录超时，请重新登录"),
    });
    window.localStorage.clear();
    next("/login");
    return;
  }
  next();
}

Vue.use(Router);

const routes = new Router({
  mode: "history",
  routes: [
    {
      path: "/calculator",
      component: () => import("@/containers/Calculator/Calculator"),
      name: "Calculator",
    },
    {
      path: "/register_tutorial",
      component: () => import("@/containers/Tutorial/Tutorial"),
      name: "Tutorial",
    },
    {
      path: "/register_tutorial_en",
      component: () => import("@/containers/Tutorial/TutorialEN"),
      name: "TutorialEN",
    },
    {
      path: "/register_tutorial_ko",
      component: () => import("@/containers/Tutorial/TutorialKR"),
      name: "TutorialKR",
    },
    {
      path: "/stake_tutorial",
      component: () => import("@/containers/Tutorial/TutorialStake"),
      name: "TutorialStake",
    },
    {
      path: "/stake_tutorial_en",
      component: () => import("@/containers/Tutorial/TutorialStakeEN"),
      name: "TutorialStakeEN",
    },
    {
      path: "/stake_tutorial_ko",
      component: () => import("@/containers/Tutorial/TutorialStakeKR"),
      name: "TutorialStakeKR",
    },
    {
      path: "/leaderboard",
      component: () => import("@/containers/Leaderboard/LeaderboardIndex"),
      name: "LeaderboardIndex",
    },
    {
      path: "/leaderboard/phase1",
      component: () => import("@/containers/Leaderboard/LeaderboardRound1"),
      name: "LeaderboardRound1",
    },
    {
      path: "/leaderboard/phase2",
      component: () => import("@/containers/Leaderboard/LeaderboardRound2"),
      name: "LeaderboardRound2",
    },
    // {
    //   path: "/register",
    //   component: Register,
    //   name: "Register",
    // },
    {
      path: "/registerSuccess",
      component: RegisterSuccess,
      name: "RegisterSuccess",
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/kollogin",
      component: KolLogin,
      name: "KolLogin",
    },
    {
      path: "/changepassword",
      component: ChangePassword,
      name: "ChangePassword",
    },
    // {
    //   path: "/genesisStake_test",
    //   component: () => import("@/containers/StakeMiner/StakeMiner"),
    //   name: "StakeMiner",
    //   beforeEnter: beforeEnter,
    // },
    {
      path: "/genesisStake",
      component: StakeMiner,
      name: "genesisStake",
      beforeEnter: beforeEnter,
    },
    {
      path: "/user",
      component: User,
      name: "User",
      beforeEnter: beforeEnter,
    },
    {
      path: "/inviteRecord",
      component: InviteRecordKOL,
      name: "InviteRecordKOL",
      beforeEnter: beforeEnter,
    },
    {
      path: "/",
      component: () => import("@/containers/CountDown/CountDown"),
      name: "CountDown",
    },
    {
      path: "/staking",
      component: () => import("@/containers/Staking/Staking"),
      name: "staking",
    },
    {
      path: "/ido",
      component: () => import("@/containers/Ido/Ido"),
      name: "ido",
    },
    {
      path: "/kol",
      component: () => import("@/containers/Kol/Kol"),
      name: "kol",
    },
    {
      path: "/airdrop",
      component: () => import("@/containers/Airdrop/Airdrop"),
      name: "airdrop",
    },
    {
      path: "/airdroptest",
      component: () => import("@/containers/Airdrop/AirdropTest"),
      name: "airdropTest",
    },
    {
      path: "/VC",
      component: () => import("@/containers/VC/VC"),
      name: "VC",
    },
    {
      path: "/vc",
      component: () => import("@/containers/VC/VC"),
      name: "vc",
    },
    {
      path: "/claim",
      component: () => import("@/containers/Released/Released"),
      name: "Released",
    },
    {
      path: "/claim100",
      component: () => import("@/containers/Claim100/Claim100"),
      name: "Claim100",
    },
    {
      path: "/claimH",
      component: () => import("@/containers/ClaimH/ClaimH"),
      name: "ClaimH",
    },
    {
      path: "/claim-ido",
      component: () => import("@/containers/ClaimIdo/ClaimIdo"),
      name: "ClaimIdo",
    },
    {
      path: "/promotionReward",
      component: () => import("@/containers/PromotionReward/PromotionReward"),
      name: "PromotionReward",
    },
    {
      path: "/promotionReward2",
      component: () => import("@/containers/PromotionReward/PromotionReward2"),
      name: "PromotionReward2",
    },
    {
      path: "/RevestingAgreement",
      component: () =>
        import("@/containers/RevestingAgreement/RevestingAgreement"),
      name: "RevestingAgreement",
    },

    {
      path: "/*",
      name: "404",
      redirect: "/",
    },
  ],
});

export default routes;
