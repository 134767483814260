
import jwt from 'jsonwebtoken';
import PhoneInput from '@/components/PhoneInput/PhoneInput'
const validateEmail = require('@/tools/validateEmail') 
export default {
  components: {
    PhoneInput
  },
  data() {
    return {
      submitLoading: false,
      tabIndex: 1,
      firstLogin: false,
      form: {
        username: '',
        phoneCode: '',
        password: '',
        personCode: ''
      }
    }
  },
  mounted() {
    const queryTab = this.$route.query.tab;
    if (queryTab !== undefined) {
      this.tabIndex = queryTab/1
    }
    const firstLogin = this.$route.query.first;
    this.firstLogin = firstLogin
  },
  methods: {
    t(params) {
      return this.$t(`login.${params}`)
    },
    onSubmit(e) {
      if (!this.form.username) {
        return this.$ctToast('请输入用户名')
      }
      if (!this.form.password) {
        return this.$ctToast('请输入密码')
      }
      this.submitLoading = true
      this.$fetch({
        headers: {
          'mock-res-index': 1
        },
        url: '/user/kolSignIn',
        method: 'POST',
        data: {
          username: this.form.username,
          password: encryptWithPublicKey(this.form.password),
        }
      }).then(res => {
        console.log(res)
        this.$toast.success()
        const token = res.token.replace('Bearer ', '')
        const tokenData = jwt.decode(token)
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('tokenData', JSON.stringify(tokenData))
        if (this.firstLogin) {
          this.$router.push("/user?first=true")
        } else {
          this.$router.push("/user")
        }
        
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
