
import PhoneInput from "@/components/PhoneInput/PhoneInputNo86";
const validateEmail = require('@/tools/validateEmail.js')
import RegisterSuccess from './RegisterSuccess'

export default {
  components: {
    PhoneInput,
    RegisterSuccess
  },
  data() {
    return {
      tabIndex: 0,
      codeCount: 0,
      codeLoading: false,
      submitLoading: false,
      disablePhone: false,
      imgCodeUrl: '',
      form: {
        phoneObj: {},
        email: '',
        phone: "",
        verifyCode: "",
        password: "",
        passwordr: "",
        walletAddress: "",
        country: '',
        province: '',
        city: '',
        address: '',
        zip: '',
        inviteCode: '',
        recommendCode: ''
      }
    };
  },
  mounted() {
    this.imgCodeUrl = this.$BASEURL+'captcha?'+Date.now()
    this.getIpLocation()
    console.log(this.$route)
    if (this.$route.query.invite) {
      this.form.inviteCode = this.$route.query.invite;
    }
    if (this.$route.query.r) {
      this.form.recommendCode = this.$route.query.r;
    }
  },
  methods: {
    getIpLocation() {
      this.$axios({
        url: 'https://dpr-api.deeper.network/user/ipLocation'
      }).then(res => {
        if (res.data.data == 'CN') {
          this.disablePhone = true
          this.tabIndex = 1
        }
      })
    },
    t(param) {
      return this.$t(`register.${param}`)
    },
    onSubmit(e) {
      
      if (this.form.password != this.form.passwordr) {
        return this.$ctToast("两次密码输入不一致");
      }
      if (!/^(0x)?[0-9a-fA-F]{40}$/.test(this.form.walletAddress)) {
        return this.$ctToast("请输入正确的DPR钱包地址");
      }
      if (this.tabIndex == 0) {
        if (!this.form.phoneObj.formattedNumber) {
          return this.$ctToast("请输入手机号码");
        }
      }
      if (this.tabIndex == 1) {
        if (!this.form.email || !validateEmail(this.form.email)) {
          return this.$ctToast("请输入正确的邮箱地址");
        }
      }
      if (!this.form.verifyCode) {
        return this.$ctToast("请输入验证码");
      }
      if (!this.form.password) {
        return this.$ctToast("请输入密码");
      }
      this.submitLoading = true;
      this.$fetch({
        headers: {
          "mock-res-index": 1,
        },
        url: "/user/register",
        method: "POST",
        data: {
          ...this.form,
          username: this.tabIndex == 0 ? this.form.phoneObj.formattedNumber : this.form.email,
          password: encryptWithPublicKey(this.form.password),
        },
      })
        .then((res) => {
          this.$refs.RegisterSuccess.open()
          // this.$router.push("/login?first=true&tab="+this.tabIndex);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    async startCodeCount() {
      this.codeCount = 60;
      while (this.codeCount > 0) {
        await this.$sleep(1000);
        this.codeCount--;
      }
    },
    getCode() {
      if (!this.form.imgCode) {
        return this.$ctToast("请输入图片验证码");
      }
      if (this.tabIndex == 0) {
        if (!this.form.phoneObj.formattedNumber) {
          return this.$ctToast("请输入正确的手机号码");
        }
      }
      if (this.tabIndex == 1) {
        if (!validateEmail(this.form.email)) {
          return this.$ctToast("请输入正确的邮箱地址");
        }
      }
      
      this.codeLoading = true;
      this.$fetch({
        headers: {
          "mock-res-index": 1,
        },
        url: this.tabIndex == 1 ? "/user/generateEmailCode" : "/user/generateCode",
        method: "POST",
        data: {
          imgCode: this.form.imgCode,
          phone: this.form.phoneObj.formattedNumber,
          email: this.form.email
        },
      })
        .then((res) => {
          this.startCodeCount();
        })
        .finally(() => {
          this.codeLoading = false;
        });
    },
  },
};
