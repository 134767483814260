
import ethService from "@/services/eth";
import { getScore, getLevel } from "./score";
import CodeModal from "./CodeModal.vue";
import DPRSlider from "@/components/DPRSlider/DPRSlider";
import WalletBtn from "./WalletBtn.vue";
import Footer from "@/components/Footer/Footer";
import CheckPrize from '@/containers/User/CheckPrize'
export default {
  components: {
    CodeModal,
    DPRSlider,
    WalletBtn,
    Footer,
    CheckPrize
  },
  data() {
    return {
      lang: {
        cn: {
          stakeSuccess: '恭喜您，质押成功！',
          round1: '第一轮',
          endSoon: '第二轮即将结束',
          ended: '活动已经结束',
          round2: '第二轮',
          round3: '第三轮',
          roundNowTip: '您当前参与的是',
          needMoveAgain: '您需要先迁移到最新周期才能添加质押。',
          startMoveAgain: '开始迁移'
        },
        en: {
          stakeSuccess: 'Congratulations you have successfully staked!',
          round1: 'Phase 1',
          round2: 'Phase 2',
          round3: 'Phase 3',
          roundNowTip: 'You are participating in',
          endSoon: 'Phase 2 will end in',
          ended: 'Activity has ended',
          needMoveAgain: 'You need to move to the latest phase before adding staking.',
          startMoveAgain: 'Start moving',
        },
        ko: {
          stakeSuccess: '성공적인 스테이킹을 축하합니다!',
          round1: '라운드 1',
          round2: '라운드 2',
          round3: '라운드 3',
          roundNowTip: '현재 참여 중입니다',
          endSoon: '두 번째 라운드가 종료됩니다',
          ended: '활동이 종료되었습니다',
          needMoveAgain: '스테이킹을 추가하기 전에 최신 사이클로 마이그레이션해야 합니다.',
          startMoveAgain: '이동 시작',
        }
      },
      ethAccount: {
        address: "",
        dprAmount: "",
        dprAmountBN: "",
        ETHBalance: '',
        chainId: 1
      },
      stakingLevel: 1,
      deeperChainAddress: "laoding...",
      userData: {},
      stakingLoading: false,
      stakingData: {
        stakingAmount: 0
      },
      periodList: [],
      userPeriodInfo: {
        round: null,
      },
      userPrize: {},
      ifReturnDPR: false,
      // stakeTime: 3000,
      stakeTime: new Date('Sun Aug 27 2021 00:00:00 GMT+0800') - Date.now(),
      // nextStakeTime: 3000,
      // stakeTime: new Date('Sun Aug 07 2021 11:00:00 GMT+0800') - Date.now(),
      nextStakeTime: new Date('Sun Aug 04 2021 11:00:00 GMT+0800') - Date.now(),
    };
  },
  async mounted() {
    if (window.ethereum) {
      await this.init();
    } else {
      await this.getUserData()
    }

    this.getPeriodInfo()
    this.checkUserPrize()
    this.getReturnData()
    if (window.ethereum && window.ethereum.chainId == "0x2a") {
      this.nextStakeTime = 0
    }
  },
  beforeDestroy() {
    this.$toast.clear();
  },
  computed: {
    scoreItem() {
      return getScore(this.stakingLevel);
    },
    needStakeAmount() {
      return (
        (
          this.scoreItem.value -
          (this.stakingData.stakingAmount / 1e18).toFixed(10) / 1
        ).toFixed(3) / 1 || 0
      );
    },
    nowStakingItem() {
      let amount = this.stakingData.stakingAmount;
      let scoreItem = getLevel((amount / 1e18).toFixed(5) / 1);
      if (scoreItem) {
        return scoreItem;
      }
      return {};
    },
    nowStakingLevel() {
      if (this.nowStakingItem.value) {
        return this.nowStakingItem.level;
      }
      return undefined;
    },
    minStakingLevel() {
      if (this.nowStakingLevel !== undefined) {
        this.stakingLevel = this.nowStakingLevel + 1;
        return this.nowStakingLevel + 1;
      }
      return 1;
    },
  },
  methods: {
    toReturnDPR() {
      this.returnLoading = true
      return this.$cFetch({
        headers: {
          'mock-res-index': 1
        },
        url: `/tokenClaim/getAccountWithPath/${this.userData.walletAddress}`,
        method: 'GET'
      }).then(res => {
        console.log(res)
        if (res.data.success && res.data.path) {
          return ethService.stakeMiner.returnDPR(this.userData.walletAddress, res.data.path).then(() => {
            this.$toast.success()
          })
        }
      }).finally(() => {
        this.returnLoading = false
      }).catch(e => {
        this.$dialog
          .confirm({
            message: `${e.message}`,
          })
          .then(() => {
          })
          .catch(() => {});
      })
    },
    getReturnData() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/checkReturnDPR'
      }).then(res => {
        console.log(res)
        if (res.success && res.data === true) {
          this.ifReturnDPR = true
        }
        
      })
    },
    checkUserPrize() {
      this.$refs.CheckPrize.check(true).then(res => {
        this.userPrize = res
      })
    },
    toTutorial() {
      let lang = this.$$i18n.locale;
      if (lang == 'cn') {
        this.$router.push('/stake_tutorial')
        return
      }
      if (lang == 'ko') {
        this.$router.push('/stake_tutorial_ko')
        return
      }
      this.$router.push('/stake_tutorial_en')
    },
    async init() {
      console.log("init");
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      await this.getUserData();
      ethService
        .init({
          onAccountsChanged: (accounts) => {
            window.location.reload();
          },
        })
        .catch((e) => {
          console.log(e);
        });
      await this.initEth();
      this.$toast.clear();
    },
    t(key) {
      return this.$t(`stakeMiner.${key}`);
    },
    async getStakingData() {
      let stakingData = await ethService.stakeMiner
        .getStakeInfo(this.ethAccount.address)
        .catch((e) => {
          console.log(e);
        });
        console.log(stakingData)
      this.stakingData = stakingData || {};
    },
    async initEth() {
      let accounts = await ethService.getAccount().catch(e => {
        this.$refs.WalletBtn.openModal()
      })
      if (!accounts || !accounts.length) {
        return
      } 
      console.log(accounts);
      this.ethAccount.address = accounts[0] && accounts[0].toLowerCase();
      if (this.ethAccount.address != this.userData.walletAddress) {
        return;
      }
      let ETHBalance = await ethService.getETHBalance(this.ethAccount.address)
      let chainId = await ethService.getChainId()
      let dprAmount = await ethService.getBalance(this.ethAccount.address);
      console.log(dprAmount);
      this.ethAccount.chainId = chainId
      this.ethAccount.ETHBalance = (ETHBalance/ 1e18).toFixed(5)/1
      this.ethAccount.dprAmountBN = dprAmount;
      this.ethAccount.dprAmount = (dprAmount / 1e18).toFixed(5) / 1;
      return this.getStakingData();
    },
    getUserData() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/user/getAccount",
      })
        .then((res) => {
          this.userData = res.data;
        })
        .catch((e) => {
          if (e === "notAuthenticated" || e === "notRegistered") {
            this.$router.replace("/login");
            window.localStorage.clear();
          }
        });
    },
    moveAgain() {
      return new Promise(resolve => {
        console.log('move to period')
        this.$dialog.alert({
          message: this.lang[this.$$i18n.locale].needMoveAgain,
          confirmButtonText: this.lang[this.$$i18n.locale].startMoveAgain
        }).then(() => {
          resolve(this.moveToPeriod()) 
        })
      })
    },
    async startStake() {
      this.stakingLoading = true;
      if (this.userPeriodInfo.round && this.userPeriodInfo.round != this.userPeriodInfo.trueRound) {
        await this.moveAgain()
      }
      return ethService.stakeMiner
        .stake(
          this.userData.walletAddress,
          this.stakingLevel,
          this.userData.deeperWallet,
          this.stakingData.stakingAmount / 1 > 0
        )
        .then((res) => {
          this.$dialog
          .alert({
            message: this.lang[this.$$i18n.locale].stakeSuccess,
            confirmButtonColor: 'green'
          })
          .then(() => {
            this.initEth();
          })
          
        })
        .catch((e) => {
          if (e.receipt && e.receipt.transactionHash) {
            this.$dialog
              .confirm({
                title: "failed",
                message: `error: ${e.receipt.transactionHash}`,
                confirmButtonText: "Show detail",
                cancelButtonText: "Close",
              })
              .then(() => {
                window.open(
                  `https://etherscan.io/tx/${e.receipt.transactionHash}`
                );
              })
              .catch(() => {});
            return;
          }
          this.$notify({
            type: "danger",
            message: e.message,
          });
        })
        .finally(() => {
          this.stakingLoading = false;
        });
    },
    async getPeriodInfo() {
      let periodList = await ethService.stakeMiner.getPeriodInfo();
      this.periodList = periodList
      let userInfo = await ethService.stakeMiner.getUserStakingPeriod(
        this.userData.walletAddress
      );
      console.log(userInfo)
      let round;
      let trueRound;
      if (userInfo && periodList.length) {
        periodList.forEach((it, index) => {
          if (it.start_time == userInfo.start_time) {
            round = index+1
          }
          if (it.end_time == userInfo.end_time || it.start_time == userInfo.end_time) {
            trueRound = index+1
          }
        })
        this.userPeriodInfo = {
          round,
          trueRound,
          start_time: userInfo.start_time,
          end_time: userInfo.end_time,
        };
      }
    },
    moveToPeriod() {
      this.stakingLoading = true;
      return ethService.stakeMiner
        .moveToPeriod(this.userData.walletAddress)
        .then((res) => {
          this.$toast.success();
          this.getPeriodInfo();
        })
        .catch((e) => {
          if (e.receipt && e.receipt.transactionHash) {
            this.$dialog
              .confirm({
                title: "failed",
                message: `error: ${e.receipt.transactionHash}`,
                confirmButtonText: "Show detail",
                cancelButtonText: "Close",
              })
              .then(() => {
                window.open(
                  `https://etherscan.io/tx/${e.receipt.transactionHash}`
                );
              })
              .catch(() => {});
            return;
          }
          this.$notify({
            type: "danger",
            message: e.message,
          });
        })
        .finally(() => {
          this.stakingLoading = false;
        });
    },
  },
};
