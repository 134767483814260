
export default {
  props: {
    value: null,
    min: null,
    title: String,
    useSlot: null,
    smTitle:String
  },
  data() {
    return {
      // levelList: ['1', '2', '3', '4', '5', '6', '7', '8']
    }
  },
  computed: {
    levelList() {
      let list= ['1', '2', '3', '4', '5', '6', '7', '8'];
      if (this.min) {
        return list.slice(this.min-1)
      }
      return list
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    }
  }
}
