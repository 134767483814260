import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

const DPR = require("@/coins/DPR/DPR.js");
const Claim = require("@/coins/Claim/Claim.js");
const Claim100 = require("@/coins/Claim/Claim100.js");
const ClaimH = require("@/coins/Claim/ClaimH.js");
const Claim2 = require("@/coins/Claim/Claim2");
const Claim3 = require("@/coins/Claim/Claim3");
const Claim4 = require("@/coins/Claim/Claim4");
const StakeMiner = require("@/coins/StakeMiner/StakeMiner");
const ClaimReward = require("@/coins/Claim/PromotionReward");
const ClaimReward2 = require("@/coins/Claim/PromotionReward2");
const Released = require("@/coins/Claim/Released");
const ReleasedLock = require("@/coins/Claim/ReleasedLock");
const ClaimIdo = require("@/coins/Claim/ClaimIdo");

// const web3 = new Web3('https://mainnet.infura.io/v3/bd22e70259d546dd832b63b7cab12ed0');
if (Web3.givenProvider) {
  window.web3 = new Web3(Web3.givenProvider);
} else {
  let providerList = [
    "https://mainnet.infura.io/v3/bd22e70259d546dd832b63b7cab12ed0",
    "https://mainnet.infura.io/v3/41f7a3c42f574496aaafdf1760dcf4a1",
    "https://mainnet.infura.io/v3/8984f83aab174c348485fbb803efb813",
  ];
  window.web3 = new Web3(
    providerList[Math.floor(Math.random() * providerList.length)]
  );
}

const dprContract = new web3.eth.Contract(DPR.abi, DPR.address);
const dprTestContract = new web3.eth.Contract(DPR.abi, DPR.address_test);
const claimContract = new web3.eth.Contract(Claim.abi, Claim.address);
const claim100Contract = new web3.eth.Contract(Claim100.abi, Claim100.address);
const ClaimHContract = new web3.eth.Contract(ClaimH.abi, ClaimH.address);
const claim2Contract = new web3.eth.Contract(Claim2.abi, Claim2.address);
const claim3Contract = new web3.eth.Contract(Claim3.abi, Claim3.address);
const claim4Contract = new web3.eth.Contract(Claim4.abi, Claim4.address);
const claimRewardContract = new web3.eth.Contract(
  ClaimReward.abi,
  ClaimReward.address
);
const claimReward2Contract = new web3.eth.Contract(
  ClaimReward2.abi,
  ClaimReward2.address
);
const releasedContract = new web3.eth.Contract(Released.abi, Released.address);
const releasedLockContract = new web3.eth.Contract(
  ReleasedLock.abi,
  ReleasedLock.address
);
const claimIDOContract = new web3.eth.Contract(ClaimIdo.abi, ClaimIdo.address);

let StakeMinerContract;
if (web3.currentProvider && web3.currentProvider.chainId == "0x2a") {
  StakeMinerContract = new web3.eth.Contract(
    StakeMiner.abi,
    StakeMiner.address_test
  );
} else {
  StakeMinerContract = new web3.eth.Contract(
    StakeMiner.abi,
    StakeMiner.address
  );
}

window.BN = Web3.utils.BN;
const BN = window.BN;

async function init(config = {}) {
  if (window.ethereum) {
    await window.ethereum.send("eth_requestAccounts");
    window.ethereum.on("accountsChanged", function (_accounts) {
      if (config.onAccountsChanged) {
        return config.onAccountsChanged(_accounts);
      }
      window.location.reload();
    });

    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });
    window.web3 = new Web3(window.ethereum);
    return true;
  }
  let providerList = [
    "https://mainnet.infura.io/v3/bd22e70259d546dd832b63b7cab12ed0",
    "https://mainnet.infura.io/v3/41f7a3c42f574496aaafdf1760dcf4a1",
    "https://mainnet.infura.io/v3/8984f83aab174c348485fbb803efb813",
  ];
  window.web3 = new Web3(
    providerList[Math.floor(Math.random() * providerList.length)]
  );
  return false;
}
const ethService = {
  init,
  async walletConnect() {
    window.localStorage.removeItem("walletconnect");
    const provider = new WalletConnectProvider({
      infuraId: "bd22e70259d546dd832b63b7cab12ed0",
    });
    await provider.enable();
    window.web3.setProvider(provider);
    return;
  },
  async metamask() {
    if (typeof window.ethereum === "undefined") {
      alert("MetaMask is not installed!");
      return;
    }
    ethereum.request({ method: "eth_requestAccounts" });
    window.web3.setProvider(Web3.givenProvider);
    return;
  },
  getAccount() {
    return web3.eth.getAccounts();
  },
  getChainId() {
    return web3.eth.getChainId();
  },
  async getBalance(account) {
    let chainId = await web3.eth.getChainId();
    console.log(chainId);
    if (chainId == "0x1") {
      return dprContract.methods.balanceOf(account).call();
    }
    return dprTestContract.methods.balanceOf(account).call();
  },
  async getETHBalance(address) {
    return web3.eth.getBalance(address);
  },
  async approve(address, amount, from) {
    let chainId = await web3.eth.getChainId();
    const dprC = chainId == "0x1" ? dprContract : dprTestContract;
    const allowance = await dprC.methods.allowance(from, address).call();
    console.log(allowance);
    if (new BN(amount).lte(new BN(allowance))) {
      return;
    }
    let maxNum = Array.from({ length: 40 }).reduce((preVal, curVal) => {
      return preVal + "F";
    }, "0X");
    return dprC.methods.approve(address, maxNum).send({ from });
  },
  tools: {
    numToBN(number, level = 18) {
      return new BN(number + "")
        .mul(new BN("10").pow(new BN(level + "")))
        .toString();
    },
  },
  claim: {
    getTotalLockAmount(account) {
      return claimContract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claimContract.methods.user_released(account).call();
    },
    checkHaveMap(account) {
      return claimContract.methods.userMap(account).call();
    },
    distribute(account, amount, proof) {
      return claimContract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return claimContract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claimContract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await claimContract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      if (havePeriod <= 0) {
        havePeriod = 0;
      }
      let releasePerPeriod = await claimContract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  claim100: {
    getTotalLockAmount(account) {
      return claim100Contract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claim100Contract.methods.user_released(account).call();
    },
    distribute(account, amount, proof) {
      return claim100Contract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return claim100Contract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claim100Contract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await claim100Contract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      if (havePeriod <= 0) {
        havePeriod = 0;
      }
      let releasePerPeriod = await claim100Contract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  claimH: {
    getTotalLockAmount(account) {
      return ClaimHContract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return ClaimHContract.methods.user_released(account).call();
    },
    distribute(account, amount, proof) {
      return ClaimHContract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return ClaimHContract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await ClaimHContract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await ClaimHContract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      if (havePeriod <= 0) {
        havePeriod = 0;
      }
      let releasePerPeriod = await ClaimHContract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  claim2: {
    getTotalLockAmount(account) {
      return claim2Contract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claim2Contract.methods.user_released(account).call();
    },
    checkHaveMap(account) {
      return claim2Contract.methods.userMap(account).call();
    },
    distribute(account, amount, proof) {
      return claim2Contract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return claim2Contract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claim2Contract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await claim2Contract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      let releasePerPeriod = await claim2Contract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  claim3: {
    getTotalLockAmount(account) {
      return claim3Contract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claim3Contract.methods.user_released(account).call();
    },
    checkHaveMap(account) {
      return claim3Contract.methods.userMap(account).call();
    },
    distribute(account, amount, proof) {
      return claim3Contract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return claim3Contract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claim3Contract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await claim3Contract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      let releasePerPeriod = await claim3Contract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  promotionReward: {
    getTotalLockAmount(account) {
      return claimRewardContract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claimRewardContract.methods.user_released(account).call();
    },
    checkHaveMap(account) {
      return claimRewardContract.methods.userMap(account).call();
    },
    distribute(account, amount, proof) {
      return claimRewardContract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return claimRewardContract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claimRewardContract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await claimRewardContract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      let releasePerPeriod = await claimRewardContract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  promotionReward2: {
    getTotalLockAmount(account) {
      return claimReward2Contract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claimReward2Contract.methods.user_released(account).call();
    },
    checkHaveMap(account) {
      return claimReward2Contract.methods.userMap(account).call();
    },
    distribute(account, amount, proof) {
      return claimReward2Contract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return claimReward2Contract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claimReward2Contract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await claimReward2Contract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      let releasePerPeriod = await claimReward2Contract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  claim4: {
    getTotalLockAmount(account) {
      return claim4Contract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claim4Contract.methods.user_released(account).call();
    },
    claim(account) {
      return claim4Contract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claim4Contract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let releasePerPeriod = await claim4Contract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  claimIdo: {
    getTotalLockAmount(account) {
      return claimIDOContract.methods.total_lock_amount(account).call();
    },
    getReleasedAmount(account) {
      return claimIDOContract.methods.user_released(account).call();
    },
    checkHaveMap(account) {
      return claimIDOContract.methods.userMap(account).call();
    },
    distribute(account, amount, proof) {
      return claimIDOContract.methods
        .distributeAndLock(account, amount, proof)
        .send({
          from: account,
        });
    },
    claim(account) {
      return claimIDOContract.methods.claimTokens().send({
        from: account,
      });
    },
    async getUnlockedAmount(account) {
      let startTime = await claimIDOContract.methods.start_time().call();
      let nowTime = parseInt(Date.now() / 1000 / (3600 * 24)) * (3600 * 24);
      let havePeriod = (nowTime - startTime) / (3600 * 24);
      let totalPeriod = await claimIDOContract.methods
        .total_release_periods()
        .call();
      if (havePeriod > totalPeriod) {
        havePeriod = totalPeriod;
      }
      if (havePeriod <= 0) {
        havePeriod = 0;
      }
      let releasePerPeriod = await claimIDOContract.methods
        .release_per_period(account)
        .call();
      return releasePerPeriod * havePeriod;
    },
  },
  stakeMiner: {
    async stake(from, level, deeperChainAddress, ifAdd) {
      if (ifAdd) {
        return StakeMinerContract.methods.addStaking(level - 1).send({
          from,
        });
      }
      await ethService.approve(StakeMiner.address, "1000000", from);
      return StakeMinerContract.methods
        .stake(deeperChainAddress, level - 1)
        .send({
          from,
        });
    },
    async getStakeInfo(account) {
      let stakingAmount = await StakeMinerContract.methods
        .getStaking(account)
        .call();
      let deeperChainAddress = await StakeMinerContract.methods
        .getUserDPRAddress(account)
        .call();
      return {
        stakingAmount,
        deeperChainAddress,
      };
    },
    async getPeriodInfo() {
      let resData = [];
      for (let index = 0; index < 5; index++) {
        resData.push(
          await StakeMinerContract.methods
            .getPeriodInfo(index)
            .call()
            .catch((e) => {
              console.log(e);
            })
        );
      }
      return resData.filter((it) => !!it);
    },
    async getUserStakingPeriod(wallet) {
      let resData = await StakeMinerContract.methods
        .getUserStakingPeriod(wallet)
        .call()
        .catch((e) => {
          console.log(e);
        });
      return resData;
    },
    async moveToPeriod(from) {
      let resData = await StakeMinerContract.methods
        .moveToLastestPeriod()
        .send({
          from,
        });
      console.log(resData);
      return resData;
    },
    async returnDPR(from, path) {
      console.log(from, path);
      let resData = await StakeMinerContract.methods
        .withdrawStaking(path, from)
        .send({
          from,
        });
      console.log(resData);
      return resData;
    },
  },
  released: {
    distributeAndLock(amount, proof, isLock, from) {
      return releasedContract.methods
        .distributeAndLock(amount, proof, isLock)
        .send({
          from,
        });
    },
  },
  releasedLock: {
    async getData(address) {
      let haveAction = await releasedContract.methods.userMap(address).call();
      let totalLock = await releasedLockContract.methods
        .total_user_lock(address)
        .call();
      let releasePeriod = await releasedLockContract.methods
        .user_release_per_period(address)
        .call();
      let released = await releasedLockContract.methods
        .user_released(address)
        .call();
      let releasedTime = await releasedLockContract.methods
        .user_released_time(address)
        .call();
      let haveClaim = !!haveAction && !(totalLock / 1);
      return {
        totalLock: totalLock / 1,
        releasePeriod,
        released: released / 1,
        releasedTime,
        haveAction,
        haveClaim,
      };
    },
    claim(from) {
      return releasedLockContract.methods.claim().send({
        from,
      });
    },
  },
};

export default ethService;
