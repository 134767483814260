import { render, staticRenderFns } from "./CheckPrize.vue?vue&type=template&id=087ef4db&scoped=true"
import script from "./CheckPrize.vue?vue&type=script&lang=js"
export * from "./CheckPrize.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "087ef4db",
  null
  
)

export default component.exports