import { render, staticRenderFns } from "./StakeMiner.vue?vue&type=template&id=e721b960&scoped=true"
import script from "./StakeMiner.vue?vue&type=script&lang=js"
export * from "./StakeMiner.vue?vue&type=script&lang=js"
import style0 from "./StakeMiner.vue?vue&type=style&index=0&id=e721b960&prod&lang=css"
import style1 from "./StakeMiner.vue?vue&type=style&index=1&id=e721b960&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e721b960",
  null
  
)

export default component.exports