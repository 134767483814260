
import PhoneInput from '@/components/PhoneInput/PhoneInput'
export default {
  components: {
    PhoneInput
  },
  data() {
    return {
      tabIndex: 0,
      codeCount: 0,
      codeLoading: false,
      submitLoading: false,
      imgCodeUrl: '',
      form: {
        imgCode: '',
        phoneObj: {},
        phone: '',
        email: '',
        verifyCode: '',
        password: '',
        passwordr: ''
      }
    }
  },
  mounted() {
    this.imgCodeUrl = this.$BASEURL+'captcha?'+Date.now()
  },
  methods: {
    t(param) {
      return this.$t('changePassword.'+param)
    },
    onSubmit(e) {
      if (this.form.passwordr != this.form.password) {
        return this.$ctToast("两次密码输入不一致")
      }
      if (this.tabIndex == 0 && !this.form.phoneObj.formattedNumber) {
        return this.$ctToast("请输入手机号码")
      }
      if (this.tabIndex == 1 && !this.form.email) {
        return this.$ctToast("请输入正确的邮箱地址")
      }
      if (!this.form.verifyCode) {
        return this.$ctToast("请输入验证码")
      }
      if (!this.form.password) {
        return this.$ctToast("请输入密码")
      }
      this.submitLoading = true;
      this.$fetch({
        headers: {
          'mock-res-index': 2
        },
        url: '/user/changePassword',
        method: 'POST',
        data: {
          username: this.tabIndex == 0 ? this.form.phoneObj.formattedNumber : this.form.email,
          verifyCode: this.form.verifyCode,
          password: encryptWithPublicKey(this.form.password)
        }
      }).then(res => {
        this.$toast.success()
        window.localStorage.clear()
        this.$router.push('/login')
      }).finally(() => {
        this.submitLoading = false
      })
    },
    async startCodeCount() {
      this.codeCount = 60;
      while (this.codeCount > 0) {
        await this.$sleep(1000)
        this.codeCount--
      }
    },
    getCode() {
      if (!this.form.imgCode) {
        return this.$ctToast("请输入图片验证码");
      }
      if (this.tabIndex == 0 && !this.form.phoneObj.formattedNumber) {
        return this.$ctToast("请输入手机号码")
      }
      if (this.tabIndex == 1 && !this.form.email) {
        return this.$ctToast("请输入正确的邮箱地址")
      }
      this.codeLoading = true
      this.$fetch({
        headers: {
          'mock-res-index': 1
        },
        url: this.tabIndex == 0 ? '/user/generateCode' : '/user/generateEmailCode',
        method: 'POST',
        data: {
          imgCode: this.form.imgCode,
          phone: this.form.phoneObj.formattedNumber,
          email: this.form.email
        }
      }).then(res => {
        this.startCodeCount()
      }).finally(() => {
        this.codeLoading = false
      })
    }
  }
}
