
export default {
  data() {
    return {
      tabIndex: 0,
      inviteList: [],
      kolList: [],
    };
  },
  mounted() {
    this.getData();
    this.getKolList();
  },
  methods: {
    t(key) {
      return this.$t(`inviteCode.${key}`);
    },
    getKolList() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/invite/kolList",
      }).then((res) => {
        this.kolList = res.data;
        console.log(res);
      });
    },
    getData() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/invite/inviteList",
      })
        .then((res) => {
          this.inviteList = res.data;
          console.log(res);
        })
        .catch((e) => {
          if (e === "notAuthenticated" || e === "notRegistered") {
            this.$router.replace("/login");
            window.localStorage.clear();
          }
        });
    },
  },
};
