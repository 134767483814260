
import copy from 'copy-to-clipboard';
export default {
  data() {
    return {
      show: false,
      codeList: [],
      clickIndex: null,
    }
  },
  methods: {
    copyUrl(code, index) {
      this.clickIndex = index
      copy(`https://dpr.deeper.network/register?r=${code}`)
      this.$toast.success()
    },
    open() {
      this.clickIndex = null
      this.$toast.loading({
        during: 0,
        forbidClick: true,
      });
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/dpr/getReferralCode'
      }).then(res => {
        if (res.success) {
          this.show = true
          this.codeList = res.data
        }
        console.log(res)
      }).finally(() => {
        this.$toast.clear()
      })
    },
  }
}
