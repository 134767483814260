
import PhoneInput from '@/components/PhoneInput/PhoneInput'
import CreateWallet from '@/components/CreateWallet/CreateWallet'
import IntentionPlan from './IntentionPlan.vue'
import InviteCode from './InviteCode.vue'
// import EditERC20 from './EditERC20.vue'
import EditEmail from './EditEmail.vue'
const validateEmail = require('@/tools/validateEmail.js')
import Footer from '@/components/Footer/Footer'
import CheckPrize from './CheckPrize.vue'
export default {
  components: {
    PhoneInput,
    CreateWallet,
    IntentionPlan,
    InviteCode,
    // EditERC20,
    EditEmail,
    Footer,
    CheckPrize
  },
  data() {
    return {
      twoFAValue: false,
      stakeDisable: true,
      stakeTime: new Date('Sun Aug 01 2021 11:00:00 GMT+0800') - Date.now(),
      validateEmail,
      autoOpenInvite: false,
      editModalShow: false,
      editLoading: false,
      editForm: {
        phoneObj: {}
      },
      userData: {
        phone: '',
        walletAddress: ''
      },
      intentionData: {
      },
      inviteItem: {

      },
      inviteActive: false,
      noEmitIntention: false,
      noEmitCreateWallet: false,
      prize: {},
      express: {},
      ifReturnDPR: false
    }
  },
  async mounted() {
    this.$toast.loading({
      forbidClick: true,
      duration: 0,
      overlay: true
    })
    await this.getData()
    this.$toast.clear()
    this.checkInviteActive()
    this.initIntention()
    this.getAutoOpenInviteStatus()
    this.getUserPrize()
    this.getUser2fa()
    this.getExpress()
    this.getReturnData()
  },
  beforeDestroy() {
    this.$toast.clear()
  },
  methods: {
    toReturnDPR() {
      this.$router.push('/genesisStake')
    },
    getReturnData() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/checkReturnDPR'
      }).then(res => {
        console.log(res)
        if (res.success && res.data === true) {
          this.ifReturnDPR = true
        }
        
      })
    },
    getExpress() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/express'
      }).then(res => {
        this.express = res.data;
      })
    },
    getUser2fa() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/2fa'
      }).then(res => {
        if (res.data) {
          this.twoFAValue = res.data.status == 'OPEN' ? true : false
        }
      })
    },
    change2fa(value) {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/2fa',
        method: 'POST',
        data: {
          status: value ? 'OPEN' : 'CLOSE'
        }
      }).then(res => {
        if (res.data) {
          this.twoFAValue = res.data.status == 'OPEN' ? true : false
        }
      })
    },
    getUserPrize() {
      this.$refs.CheckPrize.check()
    },
    onSuccessIntention(data) {
      this.intentionData = data;
      if (!this.noEmitIntention) {
        this.checkDeeperChain()
      }
    },
    onSuccessCreate() {
      this.getData()
      if (!this.noEmitCreateWallet) {
        this.checkAddress()
      }
    },
    checkDeeperChain() {
      if (!this.userData.deeperWallet) {
        this.$refs.CreateWallet.create()
        return
      }
      this.checkAddress()
    },
    toStake() {
      if (!this.userData.walletAddress || !this.userData.deeperWallet) {
        this.$ctToast('请完善钱包地址')
        return
      }
      if (!this.userData.address) {
        this.$ctToast('请尽快完善收货地址信息')
      }
      this.$router.push('/genesisStake')
    },
    checkAddress() {
      if (!this.userData.name && !this.userData.phone) {
        this.openEditModal()
      }
    },
    checkInviteActive() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/invite/inviteActive'
      }).then(res => {
        if (res.data) {
          this.inviteActive = res.data.active
          this.inviteItem = res.data
        }
      })
    },
    showInviteCode() {
      this.$refs.InviteCode.open()
    },
    t(word) {
      return this.$t(`sale.${word}`)
    },
    async initIntention() {
      let intention = await this.$refs.IntentionPlan.getData()
      this.intentionData = intention || {}
      const firstLogin = this.$route.query.first;
      if (!firstLogin) {
          return
        }
      if (!intention) {
        this.noEmitIntention = false
        return this.$refs.IntentionPlan.open()
      }
      this.checkDeeperChain()
      // this.checkAddress()
    },
    getData() {
      return this.$fetch({
        headers: {
          'mock-res-index': 2,
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/getAccount'
      }).then(res => {
        this.userData = res.data
      }).catch(e => {
        if (e === 'notAuthenticated' || e === 'notRegistered') {
          this.$router.replace('/login')
          window.localStorage.clear()
        }
      })
    },
    openEditModal() {
      this.editForm = {
        country: this.userData.country,
        province: this.userData.province,
        city: this.userData.city,
        zip: this.userData.zip,
        address: this.userData.address,
        name: this.userData.name,
        phone: this.userData.phone
      }
      this.editModalShow = true
    },
    onSubmitEdit() {
      if (!this.editForm.phoneObj || !this.editForm.phoneObj.formattedNumber) {
        return this.$Notify({
          type: 'danger',
          message: this.t('inputPhoneError')
        })
      }
      this.editLoading = true
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/editAddress',
        method: 'POST',
        data: {
          ...this.editForm,
          phone: this.editForm.phoneObj.formattedNumber
        }
      }).then(res => {
        if (res.success) {
          this.$Toast.success("success")
          this.getData().then(() => {
            this.editModalShow = false
          })
          return
        }
      }).finally(() => {
        this.editLoading = false
      })
    },
    getAutoOpenInviteStatus() {
      this.$fetch({
        url: '/user/autoOpenInviteStatus',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      }).then(res => {
        if (res.data === 'OPEN') {
          this.autoOpenInvite = true
        } else {
          this.autoOpenInvite = false
        }
      })
    }
  }
}
