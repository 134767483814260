
export default {
  data() {
    return {
      show: false,
      inviteList: []
    };
  },
  methods: {
    t(key) {
      return this.$t(`inviteCode.${key}`);
    },
    getData() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/invite/inviteList",
      })
        .then((res) => {
          this.inviteList = res.data
          console.log(res)
        })
        .catch((e) => {
          if (e === "notAuthenticated" || e === "notRegistered") {
            this.$router.replace("/login");
            window.localStorage.clear();
          }
        });
    },
    async open() {
      this.$toast.loading({
        duration: 0
      })
      await this.getData()
      this.$toast.clear()
      this.show = true;
    },
  },
};
