module.exports = {
  address: "0xCecA6B8eF05bb98D99E40cd080eddda47850b72c",
  42: {
    address: '0xD7BE13858e092048AfB42e2c8A86e0ea6Ed34a1c'
  },
  1: {
    address: "0xCecA6B8eF05bb98D99E40cd080eddda47850b72c",
  },
  abi: require('./Released.abi.json')
}
