
import axios from 'axios';

const fetch = axios.create({
  baseURL: (process.env.NODE_ENV === 'production' ? 'https://api.deeper.network/' : '/api/'),
  timeout: 180000
})


export default fetch

