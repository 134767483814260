module.exports = {
  resCommon: {
    alreadySent: '验证码已发送，请稍后再试。',
    invalidVerifyCode: '验证码不正确或已经过期',
    walletTaken: '该钱包地址已经被注册',
    differentWallet: '该用户已注册',
    alreadyRegistered: '该用户名和钱包已经被注册',
    addressTaken: '该邮寄地址已经被注册',
    invalidInviteCode: '邀请码已失效',
    invalidCredential: '用户名或密码错误',
    notRegistered: '该用户名没有被注册',
    notAuthenticated: "该账户登录失效，请重新登录",
    phoneTaken: '该手机号码已经被人使用',
    sendFailed: '发送失败',
    invalidEmail: '邮箱格式错误',
    haveParentCode: '您之前已绑定邀请码',
    invalidImgCode: '图片验证码错误',
    eth_noLogin: '您未登录，请刷新页面',
    eth_noAccount: '信息有误',
    eth_invalidAccount: '此钱包地址无法参与此次活动',
    eth_noNonce: '信息有误，请刷新后重试',
    eth_noVote: '信息有误',
    eth_haveVote: '您已经投过票了',
    eth_noDPR: '您的钱包没有DPR'
  },
  claim: {
    'WelcometoDeeperNetworkClaim': '感谢您投资Deeper Network!',
    "MyWallet": "钱包信息",
    Address: '您的钱包地址',
    'DPRBalance': '您钱包里的DPR数量',
    'ClaimInformationDPR': "领取信息 - DPR",
    "Total": "可领取总量",
    "Unlocked": "已经解锁",
    "Released": "已经领取",
    "Available": "当前可领取",
    "TransfertoMyWallet": "转账到钱包",
    "FirstTimeAuthentication": "首次申领验证",
    info1: '1、在首次领取DPR前，需要进行申领验证（只需要进行一次）',
    info2: '2、申领验证完成后，当需要将DPR转账到钱包时，请点击 “转账到钱包” 按钮',
    info3: '3、如果所需要的gas太贵，请在便宜的操作。',
    addressNotInList: '该钱包不能参与此活动。',
    releasedTime: '解锁时间',
    lockBtn: '获得5%额外奖励',
    claimBtn: '放弃奖励',
    youHaveClaimed: '您已经提币',
    DPRtoClaim: '可领取DPR总量',
    firstReward: '查看第一轮奖励',
    secondReward: '查看第二轮奖励',
    backToClaim: '查看之前的合约'
  },
  navbar: {
    'User Information': '用户信息',
    'Reset Password': '重置密码',
    'Sign Out': '登出',
    'Sign In': '登录',
    'Sign Up': '注册'
  },
  sale: {
    'UserInfo': '用户信息',
    'mobile': '手机号码',
    Username: '用户名',
    Name: '姓名',
    Phone: '手机号码',
    DPRWallet: 'ERC20钱包地址',
    'Delivery Information': '收货信息',
    "Country": '国家',
    'Province': '省份',
    'City': '城市',
    'Zip': '邮编',
    'Address': '详细地址',
    walletAddress: '地址',
    'Deeper Chain Wallet': 'Deeper Chain 钱包',
    walletTip: '可以使用Polkawallet或者支持波卡的钱包导入助记词或私钥',
    inputName: '请输入收货人',
    inputPhone: '请输入收货人手机号码',
    inputPhoneError: '手机号码不正确。',
    inputCountry: '请输入国家',
    inputProvince: '请输入省份',
    inputCity: '请输入城市',
    inputZip: '请输入邮编',
    inputAddress: '请输入详细地址精确到门牌号',
    Submit: '提交信息',
    AddressInfo: '收货信息',
    'Create Wallet': '创建钱包',
    Stake: '质押',
    MyIntention: '我的质押意向',
    MyInviteCode: '我的邀请码',
    Skip: '以后再说',
    ChangeWallet: '重新创建一个钱包',
    email: '电子邮箱',
    InviteTip: '若被邀请的用户在接下来的任意一轮质押比赛中获胜，您可以获得300DPR的奖励。',
    BindInviteCode: '绑定我的邀请人',
    InviteRecord: '邀请记录',
    '2fa': '登录需要验证码',
    tracking: '物流信息',
    express: '物流公司',
    trackingNumber: '单号',
    returnDPR: '您未获得创世矿机资格，请点击此处退币。',
    getPromotionReward: '领取推广奖励',
  },
  register: {
    'VerificationCode': '验证码',
    inputCode: '请输入验证码',
    Send: '发送验证码',
    Password: '密码',
    inputPassword: '数字+字母不少于8位',
    PasswordAgain: '重复密码',
    inputPasswordAgain: '请确认密码',
    'DPR Wallet': 'ERC20钱包',
    inputDPRWallet: '请输入ERC20钱包地址',
    toSignIn: '已有账户，去登录',
    forgetPsw: '忘记密码？',

    'UserInfo': '用户信息',
    email: '邮箱',
    inputEmail: '请输入邮箱地址',
    'mobile': '手机号码',
    'Delivery Information': '收货信息',
    "Country": '国家',
    'Province': '省份',
    'City': '城市',
    'Zip': '邮编',
    InviteCode: '邀请码',
    'Address': '详细地址',
    walletAddress: '地址',
    'Deeper Chain Wallet': 'Deeper Chain 钱包',
    inputCountry: '请输入国家',
    inputProvince: '请输入省份',
    inputCity: '请输入城市',
    inputZip: '请输入邮编',
    inputAddress: '请输入详细地址精确到门牌号',
    inputInviteCode: '请输入邀请码(如果有)',
    Submit: '提交信息',
    Edit: '修改信息',
    registerSuccess: '注册成功！',
    imgCode: '图片验证码',
    inputImgCode: '请输入图片验证码'
  },
  login: {
    'VerificationCode': '验证码',
    inputCode: '请输入验证码',
    Send: '发送验证码',
    Password: '密码',
    mobile: '手机号码',
    email: '邮箱',
    inputEmail: '请输入邮箱地址',
    Password: '密码',
    inputPassword: '请输入密码',
    'Sign In': '登录',
    'Sign Up': '前往注册',
    forgetPsw: '忘记密码？',
    imgCode: '图片验证码',
    inputImgCode: '请输入图片验证码'
  },
  changePassword: {
    mobile: '手机号码',
    email: '邮箱',
    inputEmail: '请输入邮箱地址',
    inputPassword: '数字+字母不少于8位',
    'VerificationCode': '验证码',
    inputCode: '请输入验证码',
    Send: '发送验证码',
    Password: '新密码',
    PasswordAgain: '重复新密码',
    inputPasswordAgain: '请确认新密码',
    Submit: '提交信息',
    imgCode: '图片验证码',
    inputImgCode: '请输入图片验证码'
  },
  vuePhoneComponent: {
    Callingcode: '国家或地区码',
    EnterPhoneNumber: '请输入手机号码',
  },
  createWallet: {
    clickInOrder: '请根据刚才的助记词，按顺序点击下面的按钮。',
    recordTips: '请务必用笔记下接下来的助记词，并妥善保管，不要截图也不要复制保存在互联网。如果遗失助记词，钱包里的资产将永久丢失，任何人都无法找回钱包(包括Deeper)。',
    Reset: '重置',
    Confirm: '确认',
    confirmBtnText: '我已记录和妥善保管助记词',
    confirmError: '助记词输入不正确',
    DownloadKey: '下载私钥',
    Back: '返回',
    createTips: "质押活动需要Deeper Chain钱包",
    createNow: '创建钱包',
    notNow: '以后再说'
  },
  changeWallet: {
    DeeperWallet: 'DeeperChain 钱包地址',
    inputDeeperWallet: '请输入新的正确的DeeperChain钱包地址',
    Submit: '提交',
    CreateWallet: '创建一个新的钱包'
  },
  stakeMiner: {
    RegisteredInformation: '您登记的信息',
    DPRWallet: 'ERC20钱包地址',
    'Deeper Chain Wallet': 'Deeper Chain 钱包',
    StakingInformation: '质押信息',
    DPRStaked: '已质押DPR',
    StakingLevel: '当前质押等级',
    CreditScore: '当前信力值',
    NumberOfReferralCodes: '当前可推荐名额',
    ReleaseTime: '开始解锁时间',
    DeeperChainWalletStaked: '质押的Deeper Chain钱包地址',
    'Pleaseuseyourregisteredwallettooperate': '请使用您登记的钱包来操作。如果您成功连接钱包，您会看到钱包里的DPR余额。',
    WalletInformation: '钱包信息',
    DPRBalance: 'DPR余额',
    'Pleaseselectstakinglevel': '请选择质押等级',
    Need: '您需要',
    Needmore: '您还需要',
    Get: '可获得',
    getCreditScore: '信力值',
    getNumberOfReferralCodes: '可推荐名额',
    Stake: '质押',
    clicktocopy: '点击复制邀请链接',
    invitedUser: '受邀用户',
    moveToLatestPeriod: '将质押转移到当前周期',
    toLeaderboard: '查看质押排行榜',
    startSoon: '即将开始',
    checkNetwork: '请确认您的钱包网络是ETH主网',
    ETHBalance: 'ETH余额',
    ETHBalanceTip: "因为整个质押过程在链上完成，需要支付矿工费用，所以ETH余额最好要保留0.1以上。",
    tutorial: '查看教程',
    buyDPR: '购买DPR'
  },
  intention: {
    Pleaseselectstakinglevel: '请选择您的质押意向',
    Need: '您需要',
    Get: '可获得',
    getCreditScore: '信力值',
    getNumberOfReferralCodes: '可推荐名额',
    Stake: '质押',
    Save: '保存',
    baseIncome: '基础收益(9个月质押期)',
    baseIncomeDaily: '每天基础收益',
    addIncome: '加成后收益(9个月质押期)',
    addIncomeDaily: '加成后每天基础收益',
    rewardPerson: '每个推荐名额奖励',
    tips: "这里只是记录你的意向, 真正质押的时候可以更改。",
    Predict: '预测',
    DPRPrice: 'DPR 价格'
  },
  inviteCode: {
    InviteCode: '邀请码:',
    InviteQRCode: '邀请海报',
    clicktodownload: '点击下载专属海报',
    InviteUrl: '邀请链接:',
    clicktocopy: '点击复制',
    inviteRecords: '查看我的邀请记录',
    invitedUser: '受邀用户',
    registerTime: '注册时间',
    stakingAmount: '质押数量',
    stakingTime: '质押时间',
    inviteTutorial: '点击打开专属教程'
  },
  common: {
    inform1: '质押大赛即将开始，请耐心等待，随时关注我们的通知。质押大赛开始时，将不能 修改 ERC20钱包地址 和 Deeper Chain 钱包，请尽快确认相关信息。',
    '两次密码输入不一致': '两次密码输入不一致',
    '请输入正确的DPR钱包地址': '请输入正确的ERC20钱包地址',
    '请输入手机号码': '请输入手机号码',
    '请输入正确的邮箱地址': '请输入正确的邮箱地址',
    '请输入验证码': '请输入验证码',
    '请输入密码': '请输入密码',
    '请输入正确的手机号码': "请输入正确的手机号码",
    '请先登录': '请先登录',
    '登录超时，请重新登录': '登录超时，请重新登录',
    "请完善钱包地址": '请完善ERC20 和 Deeper Chain 钱包地址',
    "请尽快完善收货地址信息": '请尽快完善收货地址信息',
    '请输入图片验证码': '请输入图片验证码'
  },
  leaderboard: {
    yourPosition: '您的排名',
    tips: '提示：数据有延迟，如未更新，请稍后再看。'
  },
  calculator: {
    Need: '质押DPR数量',
    Get: 'DPR 挖矿收益',
    title: '信用评分等级',
    DPRPrice: 'DPR 价格',
    getCreditScore: '信力值',
    ReferralTickets: '推荐码数量',
    Daily: '日收益',
    Monthly: '月收益',
    Yearly: '年收益',
    calculatorTitle: '矿机计算器',
    phase1: '第一轮',
    phase2: '第二轮',
    base: '质押升级'

  }
}