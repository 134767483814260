
export default {
  data() {
    return {
      lang: {
        en: {
          word: 'Join us and don’t miss a message',
          buy: 'Buy DPR on Uniswap'
        },
        cn: {
          word: '加入我们，时刻关注信息',
          buy: '点击购买DPR'
        },
        ko: {
          word: '우리와 함께, 항상 정보에주의를 기울이십시오',
          buy: 'Uniswap에서 DPR 구매'
        }
      }
    }
  }
}
