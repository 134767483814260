
import QRCode from 'qrcode';
import copy from 'copy-to-clipboard';
import InviteRecord from './InviteRecord.vue'
export default {
  components: {
    InviteRecord
  },
  data() {
    return {
      show: false,
      inviteCode: "",
      inviteUrl: '',
      imgUrl: '',
      loadCount: 0,
      fromAdmin: false,
      loading: true
    };
  },
  methods: {
    getInviteTutorial() {
      if (this.$$i18n.locale == 'cn') {
        window.open(`https://dpr.deeper.network/register_tutorial?code=${this.inviteCode}`)
        window.open(`https://dpr.deeper.network/stake_tutorial?code=${this.inviteCode}`)
      }
      if (this.$$i18n.locale == 'en') {
        window.open(`https://dpr.deeper.network/register_tutorial_en?code=${this.inviteCode}`)
        window.open(`https://dpr.deeper.network/stake_tutorial_en?code=${this.inviteCode}`)
      }
      if (this.$$i18n.locale == 'ko') {
        window.open(`https://dpr.deeper.network/register_tutorial_ko?code=${this.inviteCode}`)
        window.open(`https://dpr.deeper.network/stake_tutorial_ko?code=${this.inviteCode}`)
      }
    },
    loadImg() {
      this.loadCount++
      if (this.loadCount < 3) {
        return
      }
      console.log("imgLoad")
      this.drawImageCN()
      this.drawImageEN()
      this.loading = false
    },
    drawImageCN() {
      const nftCanvas = document.getElementById('poster_cn')
      const ctx = nftCanvas.getContext('2d');
      const bgImg = document.getElementById('bg_invite_cn');
      const qrImg = document.getElementById('qrcode_invite')

      nftCanvas.width = bgImg.width;
      nftCanvas.height = bgImg.height;

      ctx.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height, 0, 0, bgImg.width, bgImg.height)
      ctx.drawImage(qrImg, 0, 0, qrImg.width, qrImg.height, bgImg.width * 0.4, bgImg.height * 0.8, qrImg.width,  qrImg.height)
      ctx.font = `bold 30px Arial`;
      ctx.fillStyle = '#eeeeee'
      ctx.textAlign = 'center'
      ctx.fillText(this.inviteCode, bgImg.width * 0.5,  bgImg.height * 0.95);
    },
    drawImageEN() {
      const nftCanvas = document.getElementById('poster_en')
      const ctx = nftCanvas.getContext('2d');
      const bgImg = document.getElementById('bg_invite_en');
      const qrImg = document.getElementById('qrcode_invite')

      nftCanvas.width = bgImg.width;
      nftCanvas.height = bgImg.height;

      ctx.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height, 0, 0, bgImg.width, bgImg.height)
      ctx.drawImage(qrImg, 0, 0, qrImg.width, qrImg.height, bgImg.width * 0.4, bgImg.height * 0.8, qrImg.width,  qrImg.height)
      ctx.font = `bold 30px Arial`;
      ctx.fillStyle = '#eeeeee'
      ctx.textAlign = 'center'
      ctx.fillText(this.inviteCode, bgImg.width * 0.5,  bgImg.height * 0.95);
    },
    exportCanvasAsPNG() {
        var canvasElement = document.getElementById('poster_cn');
        var MIME_TYPE = "image/png";
        var imgURL = canvasElement.toDataURL(MIME_TYPE);
        var dlLink = document.createElement('a');
        dlLink.download = `deepernetwork-poster-${this.inviteCode}-cn.png`;
        dlLink.href = imgURL;
        dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);

        var canvasElement = document.getElementById('poster_en');
        var MIME_TYPE = "image/png";
        var imgURL = canvasElement.toDataURL(MIME_TYPE);
        var dlLink = document.createElement('a');
        dlLink.download = `deepernetwork-poster-${this.inviteCode}-en.png`;
        dlLink.href = imgURL;
        dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
    },
    openList() {
      this.$refs.InviteRecord.open()
    },
    t(key) {
      return this.$t(`inviteCode.${key}`);
    },
    copyData(data) {
      copy(data)
      this.$toast.success()
    },
    downloadImg() {
      if (this.loading) {
        return
      }
      this.exportCanvasAsPNG()
    },
    getData() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/invite/inviteCode",
      })
        .then((res) => {
          if (res.success) {
            if (!res.data) {
              window.location.reload()
              return
            }
            this.inviteCode = res.data;
            this.inviteUrl = `https://dpr.deeper.network/register?invite=${res.data}`
            QRCode.toDataURL(this.inviteUrl)
            .then(url => {
              console.log(url)
              this.imgUrl = url
            })
            .catch(err => {
              console.error(err)
            })
          }
        })
        .catch((e) => {
          if (e === "notAuthenticated" || e === "notRegistered") {
            this.$router.replace("/login");
            window.localStorage.clear();
          }
        });
    },
    getDataByCode(invteCode) {
      this.inviteCode = invteCode;
      this.inviteUrl = `https://dpr.deeper.network/register?invite=${invteCode}`
      QRCode.toDataURL(this.inviteUrl)
      .then(url => {
        console.log(url)
        this.imgUrl = url
      })
      .catch(err => {
        console.error(err)
      })
    },
    async open(invteCode) {
      this.$toast.loading({
        duration: 0
      })
      if (invteCode) {
        this.fromAdmin = true
        await this.getDataByCode(invteCode)
      } else {
        await this.getData()
      }
      this.$toast.clear()
      this.show = true;
    },
  },
};
