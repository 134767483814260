
const validateEmail = require('@/tools/validateEmail.js')
export default {
  data() {
    return {
      show: false,
      email: '',
      loading: false,
      lang: {
        en: {
          title: 'Edit Email',
          placeholder: 'Please input Email',
          save: 'Save'
        },
        cn: {
          title: '修改电子邮箱',
          placeholder: '请输入电子邮箱',
          save: '保存'
        },
        ko: {
          title: '이메일 수정',
          placeholder: '이메일을 입력하세요',
          save: '구하다'
        }
      }
    }
  },
  methods: {
    open(email) {
      this.show = true
      this.email = email || ''
    },
    save() {
      if (!validateEmail(this.email)) {
        return this.$ctToast("请输入正确的邮箱地址");
      }
      this.loading = true
      this.$fetch({
        url: '/user/email',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        method: 'PUT',
        data: {
          email: this.email
        }
      }).then(res => {
        console.log(res)
        if (res.success) {
          this.$toast.success()
          this.show = false;
          this.$emit('success')
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
