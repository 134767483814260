
export default {
  data() {
    return {
      count: 3,
      modalStatus: false
    }
  },
  mounted() {

  },
  methods: {
    open() {
      this.modalStatus = true
      this.countDown()
    },
    async countDown() {
      while (this.count>0) {
        await this.$sleep(1000)
        this.count--
      }
      this.$emit('success')
    }
  }
}
