
import {getScore} from '../StakeMiner/score'
import DPRSlider from '@/components/DPRSlider/DPRSlider'
export default {
  components: {
    DPRSlider
  },
  data() {
    return {
      show: false,
      level: 1,
      closeable: false
    };
  },
  computed: {
    scoreItem() {
      return getScore(this.level)
    },
  },
  methods: {
    t(key) {
      return this.$t(`intention.${key}`);
    },
    async init() {
      let intention = await this.getData();
      if (!intention) {
        this.show = true;
      }
    },
    open(level) {
      this.show = true
      if (level) {
        this.level = level
      }
    },
    save() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        method: 'POST',
        url: "/user/intention",
        data: {
          level: this.level
        }
      })
        .then((res) => {
          console.log(res)
          console.log(this.resolve)
          if (res.success) {
            this.show = false
            this.$emit('success', res.data)
            return res.data;
          }
        })
        .catch((e) => {});
    },
    getData() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        url: "/user/intention",
      })
        .then((res) => {
          if (res.success) {
            this.level = res.data.level/1
            if (this.level) {
              this.closeable =true
            }
            return res.data;
          }
        })
        .catch((e) => {});
    },
  },
};
